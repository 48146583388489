.h1 {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2 {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.ant-btn.ant-btn-ghost.ant-btn-round {
  color: #e66525;
  border-color: #e66525;
  height: auto;
  padding: .65em 2.5em;
  font-size: 1.16em;
}

.ant-btn.ant-btn-ghost.ant-btn-round:disabled {
  color: #e665254d;
  background: none;
  border-color: #e665254d;
}

.ant-btn.ant-btn-ghost.ant-btn-round:hover {
  color: #fff;
  background-color: #e66525;
}

.ant-btn.ant-btn-ghost.ant-btn-round:hover:disabled {
  color: #e665254d;
  background: none;
  border-color: #e665254d;
}

.ant-input {
  color: #353a52;
  box-shadow: none;
  font-size: 1.16em;
}

.ant-input:hover {
  background-color: #e2e3ea1a;
  border-color: #5b607940;
}

.ant-input:focus {
  box-shadow: none;
  background-color: #e2e3ea1a;
  border-color: #e66525;
}

.ant-input::placeholder {
  color: #9093a5;
  font-style: italic;
}

.ant-modal-header {
  padding: 0;
}

.ant-modal-header .ant-modal-title {
  letter-spacing: 0;
  text-align: center;
  padding: 0;
  font-family: Roboto Slab;
  font-size: 2em;
  font-weight: 400;
  line-height: 3.33em;
}

.ant-radio-group {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ant-radio-button-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

a {
  color: #e66525;
}

a:hover {
  color: #ee956a;
}

body {
  line-height: 1.5715;
}

.ant-pagination {
  font-weight: 400;
}

.ant-form-item {
  margin-bottom: 2.5em;
}

.ant-form-item-label label {
  font-size: 1.16em !important;
}

.ant-switch .ant-switch-handle:before {
  border-radius: 100%;
}

tbody {
  border-spacing: initial;
}

.ant-input {
  padding: 1em;
}

textarea.ant-input {
  padding: 16px;
}

.ant-upload.ant-upload-drag {
  background-color: #fff;
}

.ant-list .ant-list-item {
  display: flex;
}

.ant-dropdown-trigger {
  font-weight: 500 !important;
}

.ant-dropdown-menu {
  border-radius: 2px !important;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
  padding: 8px 6px;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu .ant-dropdown-menu-title-content {
  font-weight: 500 !important;
}

.ant-select-dropdown .ant-select-item {
  align-items: center;
  padding: 10px 12px;
}

.ant-select-selector {
  align-items: center;
  height: 60px;
}

.ant-select-selector .ant-select-selection-search {
  align-items: center;
  display: flex;
}

.ant-select .ant-select-arrow {
  margin: 2px auto auto;
  top: 0;
  bottom: 0;
}

.ant-select-selection-item {
  text-align: left;
  font-size: .9em;
}

.ant-select-item-option-content {
  font-size: .9em;
}

.ant-dropdown-trigger .anticon-down {
  transform: scale(.8);
}

.ant-dropdown-trigger .anticon-down * {
  fill: #00000040;
}

.ant-select-arrow {
  transform: scale(.8);
}

.ant-btn {
  min-height: 2.857em;
  font-weight: 500;
}

.ant-radio-button-wrapper span {
  white-space: nowrap;
}

a.ant-breadcrumb-link {
  color: #353a52 !important;
}

a.ant-breadcrumb-link:hover {
  background-color: #0000 !important;
}

.ant-btn-default:not(:disabled):hover {
  color: #e66525;
  border-color: #e66525;
}

:focus-visible {
  outline-offset: none !important;
  outline: none !important;
}

.ant-modal-confirm-content {
  white-space: pre-line;
}

html * {
  box-sizing: border-box;
}

html body {
  color: #353a52;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
}

html {
  font-size: min(11px + .3vw, 16px);
}

div:focus {
  outline: none;
}

::selection {
  color: #fff;
  background: #e66525;
}

.container_30fb83 {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

#login .ant-card-head {
  padding: 1em 1.5em;
}

.title_30fb83 {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.title_30fb83 svg {
  fill: #fff;
}

.title_30fb83 .logo_30fb83 {
  text-align: center;
  background-color: #363a50;
  border-radius: 6px;
  padding: .5em 1em;
}

.h1_063cb0 {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2_063cb0 {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.importType_063cb0 {
  text-align: center;
}

.disableColorationOnSimpleWords_063cb0 {
  text-align: start;
}

.modal_063cb0 .ant-radio-group {
  justify-content: start;
}

.modal_063cb0 .ant-modal-body .ant-upload button {
  color: #9093a5;
  background-color: #e2e3ea33;
  border-color: #5b607940;
  border-radius: 3px;
  height: 3.214em;
  padding: 0 1.43em;
  font-size: 1.16em;
  font-style: italic;
  line-height: 1;
}

.modal_063cb0 .ant-modal-body .ant-upload button:hover {
  background-color: #e2e3ea80;
  border-color: #5b607940;
}

.modal_063cb0 .ant-modal-body .ant-upload.ant-upload-select {
  width: 100%;
}

.modal_063cb0 .ant-modal-body .ant-upload.ant-upload-select .ant-btn {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal_063cb0 .ant-modal-body .ant-upload.ant-upload-select .ant-btn > * {
  margin: 0;
}

.h1_cbc6d7 {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2_cbc6d7 {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.root_cbc6d7 .ant-layout-content {
  justify-content: center;
  display: flex;
  overflow-y: auto;
}

.root_cbc6d7 .ant-tabs {
  border-bottom: 1px solid #e2e3ea;
  width: 100%;
  max-width: 150em;
  height: max-content;
  padding: 5em 3em;
}

.root_cbc6d7 .tabs_cbc6d7 .ant-tabs-tab {
  font-size: 1.15em;
}

.root_cbc6d7 .tabs_cbc6d7 .ant-tabs-tab > * {
  text-shadow: none;
}

.root_cbc6d7 .title_cbc6d7 {
  color: #fff;
}

.root_cbc6d7 .menu_cbc6d7 {
  color: #e2e3ea;
  font-size: 1.16em;
}

.root_cbc6d7 .content_cbc6d7 {
  overflow-y: auto;
}

.root_cbc6d7 .libraryTitle_cbc6d7 {
  letter-spacing: 0;
  padding-right: 5em;
  font-family: Roboto Slab;
  font-size: 2.15em;
  font-weight: 400;
}

.root_cbc6d7 .projectCard_cbc6d7:hover:not(.isInReSync_cbc6d7), .root_cbc6d7 .newProject_cbc6d7:hover {
  cursor: pointer;
}

.root_cbc6d7 .newProject_cbc6d7:hover {
  width: 95%;
  height: 95%;
}

.root_cbc6d7 .projectCard_cbc6d7, .root_cbc6d7 .newProject_cbc6d7 {
  color: #fff;
  background: #9093a5;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  place-self: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.root_cbc6d7 .projectCard_cbc6d7 > :first-child, .root_cbc6d7 .newProject_cbc6d7 > :first-child {
  text-align: center;
  padding: 0 1em .15em;
  font-size: 1.66em;
  line-height: 1.1;
}

.root_cbc6d7 .projectCard_cbc6d7 > :nth-child(2), .root_cbc6d7 .newProject_cbc6d7 > :nth-child(2) {
  color: #5b6079;
  font-size: 1.33em;
}

.root_cbc6d7 .projectCard_cbc6d7 > :nth-child(3), .root_cbc6d7 .newProject_cbc6d7 > :nth-child(3) {
  color: #e2e3ea;
}

.root_cbc6d7 .projectCard_cbc6d7 > :nth-child(2) {
  text-transform: uppercase;
  color: #e2e3ea;
  font-size: 1em;
}

.root_cbc6d7 .newProject_cbc6d7 {
  color: #5b6079;
  background: #e2e3ea;
  border-radius: 4px;
  row-gap: 1em;
  transition: width .3s, height .3s;
}

.root_cbc6d7 .newProject_cbc6d7 > :first-child > * {
  text-align: center;
  border: 1px solid #5b60791a;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 1.9em;
}

.root_cbc6d7 .newProject_cbc6d7 > :nth-child(2) {
  text-transform: inherit;
}

.root_cbc6d7 .projects_cbc6d7 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: calc(12em + 3vw);
  gap: 1.41em;
  padding-top: 1.2em;
  display: grid;
}

@media (width <= 1400px) {
  .root_cbc6d7 .projects_cbc6d7 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: calc(2.9em + 20vw);
  }
}

@media (width <= 900px) {
  .root_cbc6d7 .projects_cbc6d7 {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: calc(2.9em + 30vw);
  }
}

.root_cbc6d7 .projectCard_cbc6d7 {
  position: relative;
}

.root_cbc6d7 .projectCard_cbc6d7 .badge_cbc6d7 {
  padding: .15em .5em;
  position: absolute;
}

.root_cbc6d7 .projectCard_cbc6d7 .countryCode_cbc6d7 {
  padding: 0;
  font-size: 1.2em;
}

.root_cbc6d7 .projectCard_cbc6d7 .projectHover_cbc6d7 {
  background: #5b6079b3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.root_cbc6d7 .projectCard_cbc6d7 .projectHover_cbc6d7 > div:first-child {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.root_cbc6d7 .projectCard_cbc6d7 button {
  border: 1px solid #fff;
  border-radius: 2em;
  padding: .6em 2em;
  font-size: 1.167em;
  font-weight: bold;
}

.root_cbc6d7 .projectCard_cbc6d7 button:hover {
  cursor: pointer;
}

.root_cbc6d7 .ant-tabs {
  overflow: visible;
}

.buttonWrapper_cbc6d7 {
  margin-top: 5em;
}

.users_cbc6d7 {
  grid-auto-columns: 2em;
  grid-auto-flow: column;
  padding: 1em 0;
  display: grid;
}

.users_cbc6d7 > div {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  display: flex;
}

.users_cbc6d7 .email_cbc6d7 {
  color: #353a52;
  background-color: #e2e3ea;
  border-radius: 4px;
  padding: .5em;
  position: absolute;
  bottom: 0;
}

.breakText_cbc6d7 {
  overflow-wrap: anywhere;
}

.searchBar_cbc6d7 {
  min-width: 20em;
  height: 2em;
  font-size: 1.5em;
  position: relative;
}

.searchBar_cbc6d7 .open_cbc6d7, .searchBar_cbc6d7 .close_cbc6d7, .searchBar_cbc6d7 .input_cbc6d7 {
  justify-content: end;
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
}

.searchBar_cbc6d7 .input_cbc6d7 {
  width: 100%;
}

.searchBar_cbc6d7 .input_cbc6d7 input {
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 0 .5em;
}

.searchBar_cbc6d7 .input_cbc6d7 input:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.searchBar_cbc6d7 .open_cbc6d7, .searchBar_cbc6d7 .close_cbc6d7 {
  transform-origin: 100%;
  z-index: 1;
  padding: 0 .5em;
  right: 0;
}

.searchBar_cbc6d7 .close_cbc6d7 {
  pointer-events: none;
}

.searchBar_cbc6d7 .close_cbc6d7 span {
  pointer-events: initial;
}

.exportCountInPopover_cbc6d7 {
  color: #e66525;
  margin-left: -.1em;
  padding-left: .2em;
  padding-right: .2em;
}

.exportItemInPopover_cbc6d7 {
  padding: .4em;
  display: flex;
}

.exportItemInPopover_cbc6d7 .exportItemLabel_cbc6d7 {
  margin: 0 0 0 .8em;
}

.dropdownItemReSync_cbc6d7 {
  color: #faad14 !important;
}

.dropdownItemReSync_cbc6d7:hover {
  color: #fff !important;
  background-color: #faad14 !important;
}

.sideBarContainer_1db9c4 {
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
}

.background_1db9c4 {
  opacity: .5;
  background-color: #000;
  width: 100%;
  height: 100%;
}

.sideBar_1db9c4 {
  background-color: #fff;
  position: absolute;
}

.top_1db9c4, .bottom_1db9c4 {
  width: 100%;
}

.right_1db9c4, .left_1db9c4 {
  height: 100%;
}

.top_1db9c4 {
  top: 0;
  left: 0;
}

.bottom_1db9c4 {
  bottom: 0;
  left: 0;
}

.right_1db9c4 {
  top: 0;
  right: 0;
}

.left_1db9c4 {
  top: 0;
  left: 0;
}

.userPanelContainer_8862e6 {
  z-index: 4;
  width: 250px;
  height: 100%;
}

.userFirstLetterContainer_8862e6 {
  background: #9093a5;
  border-radius: 250px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.userFirstLetter_8862e6 {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.userInformationContainer_8862e6 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 48px 0;
  display: flex;
  box-shadow: inset 0 -1px #5b60791f;
}

.nameAndEmailContainer_8862e6 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.roleSwitcher_8862e6 {
  text-align: center;
  padding: 2em 1em;
  font-size: 1em;
}

.roleSwitcher_8862e6 .roleSelector_8862e6 {
  width: 100%;
}

.roleSwitcher_8862e6 .roleSelector_8862e6 .ant-select-selector {
  height: 32px;
}

.roleSwitcher_8862e6 .roleSelector_8862e6 .ant-select-selection-item {
  font-weight: 500;
}

.clickableWrapper_262a55:hover:not(.disabled_262a55) {
  cursor: pointer;
}

.buttonContainer_02230b {
  padding: 24px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  box-shadow: inset 0 -1px #5b60791f;
}

.buttonPrimary_02230b {
  color: #353a52;
}

.buttonPrimary_02230b:hover {
  color: #e66525;
}

.buttonAlert_02230b {
  color: #e33636;
  opacity: .8;
}

.buttonAlert_02230b:hover {
  opacity: 1;
}

.h1_834353 {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2_834353 {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.user_834353 {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  display: flex;
  position: relative;
}

.user_834353 .email_834353 {
  color: #353a52;
  background-color: #e2e3ea;
  border-radius: 4px;
  padding: .5em;
  position: absolute;
  bottom: 0;
}

.userTable_834353 {
  flex-direction: column;
  row-gap: 1em;
  width: max-content;
  padding-bottom: 1em;
  display: flex;
}

.userTable_834353 table {
  border-collapse: separate;
  border-spacing: 0 .5em;
}

.userTable_834353 table th, .userTable_834353 table td {
  padding: 0 1em 0 0;
}

.badgeStatus_2ce6be {
  color: #fff;
  background-color: #353a52;
  border-radius: 50px;
  align-items: center;
  width: auto;
  padding: .5em 1em;
  display: inline-flex;
}

.badgeStatus_2ce6be .indicator_2ce6be {
  border-radius: 50px;
  width: .6em;
  height: .6em;
}

.badgeStatus_2ce6be .indicator_2ce6be.NOT_READY_2ce6be {
  background-color: #fdff8e;
}

.badgeStatus_2ce6be .indicator_2ce6be.READY_FOR_NARRATION_2ce6be {
  background-color: #f5a4a5;
}

.badgeStatus_2ce6be .indicator_2ce6be.NARRATION_COMPLETE_2ce6be {
  background-color: #c08eff;
}

.badgeStatus_2ce6be .indicator_2ce6be.COMPLETE_2ce6be {
  background-color: #8ed6ff;
}

.badgeStatus_2ce6be .indicator_2ce6be.COMPLETE_BOOK_2ce6be {
  background-color: #78ffbe;
}

.badgeStatus_2ce6be .indicator_2ce6be.ARCHIVED_2ce6be {
  background-color: #fff;
}

.badgeStatus_2ce6be .text_2ce6be {
  color: #fff;
  padding-left: .75em;
  font-size: 1em;
  font-weight: normal;
}

.badgeStatus_2ce6be.RESYNC_2ce6be {
  background-color: #ffcb64;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.badgeStatus_2ce6be.RESYNC_2ce6be .anticon {
  color: #000;
  font-size: 16px;
}

.badgeStatus_2ce6be.RESYNC_2ce6be .text_2ce6be {
  color: #000;
}

.badgeStatus_2ce6be.small_2ce6be {
  padding: .15em .5em;
  top: .75em;
  right: .75em;
}

.badgeStatus_2ce6be.small_2ce6be .indicator_2ce6be {
  width: .5em;
  height: .5em;
}

.badgeStatus_2ce6be.small_2ce6be .text_2ce6be {
  opacity: .85;
  padding-left: .65em;
  font-size: .6em;
}

.projectExports_d5c254 {
  position: relative;
}

.projectExports_d5c254 .legendAndDelete_d5c254 {
  align-items: center;
  display: flex;
  position: relative;
}

.projectExports_d5c254 .legendAndDelete_d5c254 .deleteWrapper_d5c254 {
  z-index: 1;
  position: absolute;
  top: 15px;
  left: 0;
}

.projectExports_d5c254 .legendAndDelete_d5c254 .deleteWrapper_d5c254 .deleteBtn_d5c254 {
  border-radius: 6px;
}

.projectExports_d5c254 .legendAndDelete_d5c254 .deleteWrapper_d5c254 .deleteBtn_d5c254 .deleteIcon_d5c254 {
  font-size: 1.3em;
}

.projectExports_d5c254 .legendAndDelete_d5c254 .legendAlert_d5c254 {
  margin-left: 80px;
  position: absolute;
  top: 14px;
}

.projectExports_d5c254 .legendAndDelete_d5c254 .legendAlert_d5c254 .ant-alert-message {
  opacity: .8;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper {
  width: 100%;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .ant-table-cell.cell-config {
  padding-top: 0;
  padding-bottom: 0;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .ant-table-cell.cell-config p {
  opacity: .8;
  margin-bottom: 0;
  font-size: .8em;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .ant-table-cell .anticon {
  font-size: 1.4em;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .ant-table-cell .anticon.iconWaiting_d5c254 {
  color: #9093a5;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .ant-table-cell .anticon.iconDone_d5c254 {
  color: #61c374;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .ant-table-cell .anticon.iconFailed_d5c254 {
  color: red;
}

.projectExports_d5c254 .projectExportsList_d5c254 .ant-table-wrapper .cancelIcon_d5c254 {
  color: #e66525;
}

.root_397f1e {
  width: 95vw !important;
}

.root_397f1e .ant-modal-content {
  flex-direction: column;
  height: 95vh;
  display: flex;
}

.root_397f1e .ant-modal-content .ant-modal-body {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.header_b33b83.ant-layout-header {
  color: #e2e3ea;
  background: #353a52;
  height: auto !important;
}

.header_b33b83.ant-layout-header svg {
  fill: #fff;
}

.tooltipContent_b33b83 {
  min-width: 7.2em;
}

.tooltipContent_b33b83 .version_b33b83 {
  margin-bottom: 0;
  font-size: 1em;
}

.userFirstLetterWrapper_b33b83 {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.userFirstLetter_b33b83 {
  background: #9093a5;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

.paragraph .space {
  display: inline-block;
}

.rheseWrapper {
  white-space: nowrap;
  display: inline-block;
}

.insecable {
  background-color: #9093a5;
}

.isSearchResult {
  background-color: #6aa5eb;
}

.rhese .rheseNumber {
  user-select: none;
  display: none;
}

.rhese .rheseNumber::selection {
  color: inherit;
  background-color: #0000;
}

.paragraph.TITRE_1 {
  letter-spacing: -.32px;
  text-align: center;
  font-size: 1.15em;
  font-weight: bold;
}

.paragraph.TITRE_2 {
  text-transform: uppercase;
  letter-spacing: -.28px;
  font-weight: bold;
}

.paragraph.TITRE_3 {
  letter-spacing: -.28px;
  text-underline-position: under;
  font-weight: 500;
  text-decoration: underline;
}

.paragraph.TITRE_4:before {
  content: "→ ";
}

.paragraph.TITRE_4 {
  letter-spacing: -.28px;
  color: #0009;
  font-weight: 500;
}

.paragraph.INTRO {
  letter-spacing: -.28px;
  color: #000000b3;
  font-weight: 500;
}

.paragraph.RESUME {
  color: #000c;
  font-weight: 300;
}

.paragraph.DIALOGUE {
  line-height: 1.42;
}

.highlightedSpace {
  background-color: #6aa5eb;
}

.borderGreen {
  border-right: 4px solid #61c374;
}

.circleContainerTooltipClasses {
  position: relative;
}

.circleTooltipClasses {
  background-color: #e66525;
  border-radius: 100%;
  min-width: 3.5px;
  max-width: 3.5px;
  min-height: 3.5px;
  max-height: 3.5px;
  margin: auto;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
}

.root_a7ccd3 {
  padding: 0;
}

.search_a7ccd3 input {
  border: none;
  width: 100%;
  font-size: 1.2em;
}

.ant-dropdown-trigger {
  font-size: .9em;
}

.dropDownMenu_a7ccd3 * {
  font-size: 1em;
}

.result_a7ccd3 {
  grid-auto-flow: column;
  font-size: 1em;
  display: grid;
}

.result_a7ccd3:hover {
  cursor: pointer;
  background-color: #e2e3ea;
}

.result_a7ccd3 .arrow_a7ccd3 {
  opacity: 0;
  transform: rotate(45deg);
}

.result_a7ccd3:hover .arrow_a7ccd3 {
  opacity: 1;
}

.nbResults_a7ccd3 {
  text-align: center;
  border-top: 1px solid #e2e3ea;
  margin: 0;
  padding: 1em;
  font-size: 1.3em;
  font-style: italic;
}

.pagination_a7ccd3 {
  align-self: center;
}

.root_fbf696 #preview ::selection {
  color: #fff;
  background: #6aa5eb;
}

.existingContainer_10bbf2 {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.pageBar_10bbf2 button {
  background: #fff;
  border: 1px solid #e2e3ea;
  border-radius: 5em;
  justify-content: center;
  align-items: center;
  width: 3.33em;
  height: 3.33em;
  margin: 0 .4em;
  padding: 0;
  display: flex;
}

.pageBar_10bbf2 button svg {
  font-size: 1em;
}

.pageBar_10bbf2 button:hover {
  cursor: pointer;
}

.pageBar_10bbf2 button:disabled {
  opacity: .3;
}

.pageBar_10bbf2 button:disabled:hover {
  cursor: not-allowed;
}

.pageBar_10bbf2 .addingMute_10bbf2 {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 58.3em;
  padding: 2.3em 5.4em;
  line-height: 2.8em;
  display: flex;
}

.pageBar_10bbf2 .addingMute_10bbf2 > div > span {
  color: #9093a5;
  padding-left: 1em;
  font-family: Roboto Slab;
  font-weight: 300;
}

.fontSize_10bbf2 {
  z-index: 800;
  position: absolute;
  bottom: 12em;
  right: 8em;
}

.fontSize_10bbf2 span {
  font-size: 1.5em;
}

.goToPage_10bbf2 {
  --width: 58.3em;
  --margins: calc(100vw - var(--width));
  --margin: calc(var(--margins) / 2);
  top: 2em;
  right: calc(var(--margin)  - 5em);
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  padding: .8em;
  font-size: 1em;
  display: flex;
  position: absolute;
}

.bookIcon_10bbf2 {
  background-color: #5b6079;
}

.bookIcon_10bbf2:hover, .bookIcon_10bbf2.ant-popover-open {
  background-color: #e66525;
}

.popoverGoTo_10bbf2 {
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  padding: .5em 1em;
  display: flex;
}

.popoverGoTo_10bbf2 .popoverGoToSpanCount_10bbf2 {
  flex-shrink: 0;
  margin-left: -7px;
  font-size: .9em;
  display: flex;
}

.popoverGoTo_10bbf2 .ant-input-number {
  border-radius: 2px;
  width: 4em;
}

.popoverGoTo_10bbf2 .ant-input-number-input {
  width: 4em;
  height: 1.9em;
  font-size: .9em;
}

.popoverGoTo_10bbf2 .ant-btn {
  height: 2.1em;
  padding-left: .6em;
  padding-right: .6em;
  font-size: .9em;
}

.popoverGoTo_10bbf2 .ant-btn:hover svg path {
  fill: #e66525;
}

.popoverGoTo_10bbf2 .ant-btn svg {
  margin-left: .5em;
  font-size: .75em;
}

.popoverGoTo_10bbf2 .ant-btn svg path {
  transition: all .5s;
}

.h1_77d7ff {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2_77d7ff {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.root_77d7ff {
  --width: 58.3em;
  --height: 60em;
  --padding-v: calc(var(--width) * .11);
  --padding-h: calc(var(--width) * .093);
  --column-gap: calc(var(--padding-h) * 2);
  align-items: center;
  display: flex;
  position: relative;
}

.root_77d7ff .contentWrapper_77d7ff {
  width: var(--width);
  background: #fff;
  flex-direction: column;
  display: flex;
}

.root_77d7ff .a4Wrapper_77d7ff {
  letter-spacing: .14px;
  z-index: 800;
  width: 100%;
  height: calc(var(--height)  + (var(--padding-v) * 2));
  padding-top: calc(var(--padding-v) / 2);
  font-family: Roboto;
  font-weight: 400;
  line-height: 2;
  overflow-y: hidden;
}

.overflowChecker_77d7ff {
  flex: 1;
  overflow-y: hidden;
}

.root_77d7ff .content_77d7ff > * {
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--padding-h);
}

.root_77d7ff .rhese {
  box-decoration-break: clone;
  padding: .2em 0;
}

.root_77d7ff .topBar_77d7ff {
  margin: calc(var(--padding-v) / 2) var(--padding-h) 0 var(--padding-h);
  border-bottom: 1px solid #e2e3ea;
  flex-direction: row;
  display: flex;
}

.root_77d7ff .topBar_77d7ff .totalPageNumber_77d7ff {
  color: #9093a5;
  align-items: center;
  padding-left: .5em;
  font-size: 1.5em;
  display: flex;
}

.root_77d7ff .topBar_77d7ff .pageNumber_77d7ff {
  height: 2em;
  margin-top: .35em;
}

.root_77d7ff .topBar_77d7ff .totalPageNumber_77d7ff, .root_77d7ff .topBar_77d7ff .pageNumber_77d7ff {
  background-color: #0000;
}

.root_77d7ff .topBar_77d7ff .chapterName_77d7ff {
  font-size: 1.5em;
  box-shadow: none !important;
  background-color: #0000 !important;
  border: none !important;
}

.root_77d7ff .showMore_77d7ff {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5em;
  display: flex;
}

.root_77d7ff .showMore_77d7ff:hover {
  cursor: pointer;
}

.root_c0a364 .rheseWrapper {
  width: 100%;
}

.root_c0a364 .rhese {
  display: inline-block;
  position: relative;
}

.root_c0a364 .rhese:hover .rheseNumber {
  display: initial;
}

.root_c0a364 .rhese .rheseNumber {
  background-color: #9093a5a8;
  padding: .12em .48em;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-.2em - 100%));
}

.root_c0a364 .rhese2 .rheseNumber {
  background-color: #e2e3eafc;
}

.root_c0a364 #preview ::selection {
  color: #000;
  background: #6aa5eb;
}

.root_c0a364 .rhese.active {
  color: #fff;
  background-color: #6aa5eb;
}

.root_c0a364 .paragraph {
  flex-direction: column;
  row-gap: .2em;
  display: flex;
}

.root_c0a364 .paragraph .rhese {
  background-color: #9093a5a8;
  border-left: .25em solid #5b6079;
  width: max-content;
  max-width: 100%;
  padding: .12em .48em;
}

.root_c0a364 .paragraph .rhese2 {
  background-color: #e2e3eafc;
}

.root_c0a364 .paragraph .space {
  display: none;
}

.root_c0a364 .paragraph .rhese.warningLight {
  border-left: .25em solid #b4b09f;
}

.root_c0a364 .paragraph .rhese.warning {
  border-left: .25em solid #e66525;
}

.root_c0a364 .paragraph .rhese.warningStrong {
  border-left: .25em solid #e33636;
}

.customRheseWrapper_c0a364 {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
}

.customRheseWrapper_c0a364 button {
  opacity: 0;
  position: absolute;
  right: 0;
}

.customRheseWrapper_c0a364:hover button {
  opacity: 1;
}

.editRhese_c0a364 .btnBar_c0a364 {
  justify-content: center;
  align-items: center;
  padding-top: 3em;
  display: flex;
}

.clonedRheseSyllable_c0a364 {
  text-align: center;
}

.clonedRheseSyllable_c0a364 .rhese {
  padding: .12em 0;
  font-size: 2em;
}

.clonedRheseSyllable_c0a364 .rhese .pair {
  color: #1f3fdd;
}

.clonedRheseSyllable_c0a364 .rhese .impair {
  color: #e33636;
}

.clonedRheseSyllable_c0a364 .rhese .muet {
  color: #9093a5;
}

.clonedRhesePhoneme_c0a364 {
  text-align: center;
}

.clonedRhesePhoneme_c0a364 .rhese {
  padding: .12em 0;
  font-size: 2em;
}

.clonedRhesePhoneme_c0a364 .rhese .consonant {
  color: #1f3fdd;
}

.clonedRhesePhoneme_c0a364 .rhese .vowel {
  color: #e33636;
}

.clonedRhesePhoneme_c0a364 .rhese .complicated {
  color: #c846ff;
}

.clonedRhesePhoneme_c0a364 .rhese .muet {
  color: #9093a5;
}

.clonedRheseDefinition_c0a364 {
  text-align: center;
}

.clonedRheseDefinition_c0a364 .rhese {
  padding: .12em 0;
  font-size: 2em;
}

.clonedRheseDefinition_c0a364 .definition.explicit {
  background-color: #e2e3ea;
  border-left: .3em solid #23959f;
}

.clonedRheseDefinition_c0a364 .definition.implicit {
  background-color: #e2e3ea;
  border-left: .3em solid #c125e6;
}

.clonedRheseDefinition_c0a364 .definition.ndbp {
  background-color: #e2e3ea;
  border-left: .3em solid #551ca2;
}

.clonedRheseDefinition_c0a364 .definition.image {
  background-color: #e2e3ea;
  border-left: .3em solid #3f51b5;
}

.root_b7fdae #preview ::selection {
  color: #fff;
  background: #6aa5eb;
}

.root_b7fdae .rhese:hover {
  background: #6aa5eb4d;
}

.root_b7fdae.syllables_b7fdae .rhese {
  padding: .12em 0;
}

.root_b7fdae.syllables_b7fdae .rhese .letter {
  position: relative;
}

.root_b7fdae.syllables_b7fdae .rhese .pair {
  color: #1f3fdd;
}

.root_b7fdae.syllables_b7fdae .rhese .impair {
  color: #e33636;
}

.root_b7fdae.syllables_b7fdae .rhese .muet {
  color: #9093a5;
}

.root_b7fdae.phonemes_b7fdae .rhese {
  padding: .12em 0;
}

.root_b7fdae.phonemes_b7fdae .rhese .letter {
  position: relative;
}

.root_b7fdae.phonemes_b7fdae .rhese .consonant {
  color: #1f3fdd;
}

.root_b7fdae.phonemes_b7fdae .rhese .vowel {
  color: #e33636;
}

.root_b7fdae.phonemes_b7fdae .rhese .complicated {
  color: #c846ff;
}

.root_b7fdae.phonemes_b7fdae .rhese .muet {
  color: #9093a5;
}

.mode_b7fdae {
  position: fixed;
  bottom: 10em;
  left: 10em;
}

.clorationMode span {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.filter_f19d45:hover {
  cursor: pointer;
}

.filter_f19d45 {
  border-bottom: 1px solid #e2e3ea;
  width: 90%;
  padding: 1em 2em;
  transition: all .3s;
}

.filter_f19d45 span {
  display: inline-block;
}

.filter_f19d45:first-child {
  color: #9093a5;
}

.filter_f19d45:nth-child(2) {
  color: #23959f;
}

.filter_f19d45:nth-child(3) {
  color: #c125e6;
}

.filter_f19d45:nth-child(4) {
  color: #551ca2;
}

.filter_f19d45:nth-child(5) {
  color: #3f51b5;
}

.selectedFilter_f19d45 {
  width: 100%;
}

.modal_f19d45 .ant-modal-body {
  flex-direction: column;
  row-gap: 3em;
  padding: 4em 8em;
  display: flex;
}

.root_f19d45 #preview ::selection {
  color: #fff;
  background: #6aa5eb;
}

.root_f19d45 .rhese:hover {
  background: #6aa5eb4d;
}

.root_f19d45 .definition {
  border-left: .3em solid #0000;
  padding: 0 .2em;
}

.root_f19d45 .all .definition.explicit, .root_f19d45 .explicit .definition.explicit {
  background-color: #e2e3ea;
  border-left: .3em solid #23959f;
}

.root_f19d45 .all .definition.implicit, .root_f19d45 .implicit .definition.implicit {
  background-color: #e2e3ea;
  border-left: .3em solid #c125e6;
}

.root_f19d45 .all .definition.ndbp, .root_f19d45 .ndbp .definition.ndbp {
  background-color: #e2e3ea;
  border-left: .3em solid #551ca2;
}

.definitionActions_f19d45 .action_f19d45:hover {
  color: #fff !important;
  background-color: #61c374 !important;
}

.definitionActions_f19d45 :first-child .action_f19d45 {
  color: #9093a5;
}

.definitionActions_f19d45 :nth-child(2) .action_f19d45 {
  color: #61c374;
}

.root_a2a3cc .paragraph:hover {
  cursor: pointer;
}

.root_a2a3cc .paragraph:hover .rhese {
  background: #6aa5eb4d;
}

.root_a2a3cc .paragraph .rhese.active, .root_a2a3cc .paragraph .rhese.activeStart, .root_a2a3cc .paragraph .rhese.activeEnd {
  background: #6aa5eb;
}

.root_a2a3cc ::selection {
  color: inherit;
  background: #b4b09f;
}

.root_a2a3cc .separator {
  caret-color: #0000;
  color: #e66525;
  border: 1px solid #0000;
  padding: 0 .2em;
  font-size: 1.5em;
  line-height: 1em;
}

.root_a2a3cc .separator.selected {
  border: 1px solid #e66525;
}

.root_a2a3cc .separator:hover {
  cursor: pointer;
}

.root_a2a3cc .lettersCount_a2a3cc {
  color: #fff;
  text-align: center;
  background-color: #9093a5;
  border-radius: 2px;
  padding: .5em 1em;
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.root_a2a3cc.segmentationInfo_a2a3cc .lettersCount_a2a3cc {
  display: block;
}

.root_a2a3cc .rhese .GRAS {
  font-weight: bold;
}

.root_a2a3cc .rhese .ITALIQUE {
  font-style: italic;
}

.root_a2a3cc .rhese .MAJUSCULE {
  text-transform: uppercase;
}

.root_a2a3cc .rhese .MINUSCULE {
  text-transform: lowercase;
}

.root_a2a3cc .rhese .EXPOSANT {
  font-variant-position: super;
}

.root_a2a3cc .rhese .INDICE {
  font-variant-position: sub;
}

.root_a2a3cc .rhese .CITATION {
  border-top: 1px solid #9093a5;
  border-bottom: 1px solid #9093a5;
}

.side_a2a3cc {
  --width: 58.3em;
  --margins: calc(100vw - var(--width));
  --margin: calc(var(--margins) / 2);
  pointer-events: none;
  top: 2em;
  left: 0;
  left: calc(var(--margin)  - 5em);
  flex-direction: column;
  row-gap: 1em;
  font-size: 1em;
  display: flex;
  position: absolute;
  transform: translateX(-50%);
}

.side_a2a3cc .sideMenu_a2a3cc {
  justify-content: end;
  font-size: 1.2em;
  display: flex;
  position: relative;
}

.side_a2a3cc .sideMenu_a2a3cc menu {
  pointer-events: auto;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  width: 2.5em;
  margin: 0;
  padding: 0;
  display: flex;
  box-shadow: 5px 5px 15px #00000014;
}

.side_a2a3cc .sideMenu_a2a3cc menu > div {
  border-bottom: 1px solid #e2e3ea;
  padding: .2em .3em;
}

.side_a2a3cc .sideMenu_a2a3cc menu > div:hover {
  cursor: pointer;
  background-color: #e2e3ea;
}

.dropZone_a2a3cc {
  text-transform: uppercase;
  z-index: 9999;
  border: 2px dashed #6aa5eb;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: .5em;
  font-size: 1em;
  display: flex;
  position: relative;
}

.image_a2a3cc {
  background-color: #fafafa;
  border: 2px solid #e2e3ea;
  border-radius: 3px;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: .5em;
  padding: 1em 2em;
  display: grid;
}

.image_a2a3cc:hover {
  cursor: pointer;
}

.image_a2a3cc .left_a2a3cc {
  text-transform: uppercase;
  grid-column: 1;
  justify-content: flex-start;
  align-items: center;
  column-gap: .5em;
  display: flex;
}

.image_a2a3cc .center_a2a3cc {
  flex-direction: column;
  grid-column: 2;
  place-self: center;
  align-items: center;
  display: flex;
}

.image_a2a3cc .right_a2a3cc {
  grid-column: 3;
  place-self: center end;
}

.delete_a2a3cc {
  transition: all .3s;
}

.delete_a2a3cc:hover {
  color: #e33636;
  font-size: 1.1em;
}

.dialogueWrapper_a2a3cc {
  position: relative;
}

.dialogueWrapper_a2a3cc:hover {
  cursor: pointer;
}

.dialogue_a2a3cc {
  z-index: 10;
  opacity: 1;
  align-items: center;
  height: 100%;
  padding: 0 1em;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-90%);
}

.dialogueButton_a2a3cc {
  justify-content: center;
  align-items: center;
  display: flex;
}

#header_fa18b1 {
  pointer-events: initial;
  z-index: 999;
  grid-template-columns: 2fr 5fr 2fr;
  height: 5.833em;
  padding: 0;
  display: grid;
  position: relative;
}

#header_fa18b1 #tabs_fa18b1 {
  grid-column: 2;
  width: 100%;
  height: 100%;
}

#header_fa18b1 #tabs_fa18b1 > * {
  width: 100%;
  height: 100%;
  margin: 0;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab {
  border-right: 1px solid #5b607912;
  justify-content: center;
  width: 6.66em;
  margin: 0;
  padding: 0;
  display: flex;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab svg {
  color: #5b6079;
  fill: #5b6079;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab:first-child {
  border-left: 1px solid #5b607912;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab:hover {
  background-color: #9093a51a;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab:hover svg path, #header_fa18b1 #tabs_fa18b1 .ant-tabs-tab:hover svg circle, #header_fa18b1 #tabs_fa18b1 .ant-tabs-tab:hover svg rect {
  fill: #e66525;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab-active {
  background-color: #9093a51a;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab-active svg path, #header_fa18b1 #tabs_fa18b1 .ant-tabs-tab-active svg circle, #header_fa18b1 #tabs_fa18b1 .ant-tabs-tab-active svg rect {
  fill: #e66525;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-tab-btn {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

#header_fa18b1 #tabs_fa18b1 .ant-tabs-nav-list {
  margin: 0 auto;
}

.nav_fa18b1 {
  flex-direction: row;
  align-items: center;
  column-gap: .5em;
  padding: .2em 0 .2em 1.5em;
  display: flex;
  overflow: hidden;
}

.nav_fa18b1 button {
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  border: none;
  line-height: 1;
  overflow: hidden;
}

.nav_fa18b1 button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.logo_fa18b1 {
  padding: 0;
  font-size: 2em;
  line-height: 1;
}

.search_fa18b1 {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3em;
  display: flex;
}

.search_fa18b1:hover {
  cursor: pointer;
}

.search_fa18b1:hover svg {
  fill: #e66525;
}

.search_fa18b1 svg {
  fill: #9093a5;
}

.wrapper_fa18b1 {
  z-index: 999;
  pointer-events: none;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.panelWrapper_874c04 {
  z-index: 900;
  width: 100%;
  height: 100vh;
  position: absolute;
  transform: translateY(-100%);
}

.panelWrapper_874c04 .transparentBackground_874c04 {
  background-color: #9093a580;
  width: 100%;
  height: 100%;
}

.projectPanel_874c04 {
  background-color: #fff;
  width: 100vw;
  height: 40em;
  display: flex;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.projectPanel_874c04 h4 {
  font-size: 3em;
}

.projectPanel_874c04 .cover_874c04 {
  color: #fff;
  background-color: #9093a5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34%;
  min-width: 24em;
  max-width: 40em;
  display: flex;
}

.projectPanel_874c04 .cover_874c04 h3 {
  color: #fff;
}

.projectPanel_874c04 .cover_874c04 .name_874c04 {
  font-size: 2.5em;
}

.projectPanel_874c04 .cover_874c04 .author_874c04 {
  font-size: 1.5em;
}

.projectPanel_874c04 .tabs_874c04 {
  width: 66%;
  overflow-x: auto;
}

.projectPanel_874c04 .tabs_874c04 .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  padding: 1em 0;
}

.projectPanel_874c04 .ant-tabs-nav-wrap {
  justify-content: center;
  align-items: center;
  width: 15em;
}

.projectPanel_874c04 .ant-tabs-nav-wrap .ant-tabs-tab-btn {
  font-size: 1.5em;
}

.projectPanel_874c04 .ant-tabs-content-holder {
  overflow-y: auto;
}

.projectPanel_874c04 .ant-tabs-content, .projectPanel_874c04 .ant-tabs-tabpane {
  min-height: 100%;
}

@media (width <= 1200px) {
  .projectPanel_874c04 .cover_874c04 {
    width: 25%;
    min-width: 16em;
  }

  .projectPanel_874c04 .cover_874c04 .name_874c04 {
    font-size: 2em;
  }

  .projectPanel_874c04 .cover_874c04 .author_874c04 {
    font-size: 1em;
  }

  .projectPanel_874c04 .tabs_874c04 {
    width: unset;
    flex: 1;
  }

  .projectPanel_874c04 .tabs_874c04 .ant-tabs-nav-wrap {
    width: 12em;
  }

  .projectPanel_874c04 .tabs_874c04 .ant-tabs-nav-wrap .ant-tabs-tab {
    margin-left: -4px;
    font-size: 90%;
  }
}

.wrapper_874c04 {
  z-index: 999;
  pointer-events: none;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.step_874c04 {
  flex-direction: column;
  row-gap: 1em;
  padding: 1em 0;
  display: flex;
}

.step_874c04 > label {
  font-size: 1.25em;
}

.reloadButton_874c04 {
  margin-top: 2em;
}

.projectStatus_874c04 {
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  display: grid;
}

.projectStatus_874c04 .ant-radio-group.ant-radio-group-outline {
  display: flex;
}

.projectStatus_874c04 .ant-radio-button-wrapper {
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.infos_874c04, .config_874c04, .checklist_874c04, .userAccess_874c04, .progress_874c04 {
  min-height: 100%;
  padding: 2em;
  font-size: 1.2em;
  position: relative;
}

.infos_874c04 > :not(h4), .config_874c04 > :not(h4), .checklist_874c04 > :not(h4), .userAccess_874c04 > :not(h4), .progress_874c04 > :not(h4) {
  font-size: 1.2em;
}

.infos_874c04 ul, .config_874c04 ul, .checklist_874c04 ul, .userAccess_874c04 ul, .progress_874c04 ul {
  padding: 1em 0;
  text-decoration: none;
  list-style: none;
}

.infos_874c04 ul li, .config_874c04 ul li, .checklist_874c04 ul li, .userAccess_874c04 ul li, .progress_874c04 ul li {
  font-weight: normal;
}

.infos_874c04 a, .config_874c04 a, .checklist_874c04 a, .userAccess_874c04 a, .progress_874c04 a {
  font-size: 1.2em;
}

.radio_874c04 {
  justify-content: start;
}

.customColorConfig_874c04 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  width: max-content;
  margin-bottom: .5em;
  display: flex;
}

.add_874c04 {
  margin: .5em 0;
}

.config_874c04 h3 {
  padding-bottom: 1em;
}

.divider_874c04 {
  margin: 2em 0;
}

.badgeStatus_874c04 {
  margin-top: 1em;
}

.spinnerContainer_874c04 {
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.step_7db6a7 h4 {
  margin-bottom: 1em;
  line-height: 1.1em;
}

.button_7db6a7 {
  min-width: 10em;
  height: unset;
  background-color: #353a52;
  border-color: #353a52;
  border-radius: 6px;
  padding: .3em 1em;
  font-weight: normal;
  font-size: .7em !important;
}

.button_7db6a7.ant-btn-primary {
  border: none;
}

.legendAndButton_7db6a7 {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.legendAndButton_7db6a7 .legend_7db6a7 {
  margin-bottom: 1em;
  font-size: .7em;
  font-style: italic;
  font-weight: normal;
  display: block;
}

.complete_7db6a7 {
  color: #38ce47;
  pointer-events: none;
  box-shadow: none;
  background-color: #d2ffd7;
  border-color: #d2ffd7;
}

.divider_7db6a7 {
  background-color: #e2e3ea;
  margin: 2em;
}

.divider_7db6a7 div {
  width: 2px;
}

.narrationState_7db6a7 {
  margin-top: 2em;
}

.narrationState_7db6a7 .progressContainer_7db6a7 {
  margin-bottom: 1em;
  position: relative;
}

.narrationState_7db6a7 .progressContainer_7db6a7 .textContainer_7db6a7 {
  flex: 1;
  justify-content: space-between;
  margin-bottom: -.5em;
  display: flex;
}

.narrationState_7db6a7 .progressContainer_7db6a7 .textContainer_7db6a7 .progressTitle_7db6a7 {
  font-size: .8em;
  font-weight: normal;
}

.narrationState_7db6a7 .progressContainer_7db6a7 .textContainer_7db6a7 .iconChecked_7db6a7 {
  color: green;
  margin-right: .5em;
  font-size: .7em;
}

.narrationState_7db6a7 .progressContainer_7db6a7 .textContainer_7db6a7 .progressBarLegend_7db6a7 {
  font-size: .7em;
  font-weight: normal;
}

.narrationState_7db6a7 .buttonMarkBookAsDone_7db6a7 {
  width: 100%;
  margin-top: 1em;
}

.container_7db6a7 {
  display: flex;
}

.stepBookCompleted_7db6a7 .legend_7db6a7 {
  min-width: 15em;
  max-width: 60%;
}

.stepBookCompleted_7db6a7 .button_7db6a7 {
  border-width: 2px;
  background-color: #0000 !important;
}

html, body {
  width: 100%;
  height: 100%;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

.h1_84b0db {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2_84b0db {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.root_84b0db .ant-layout-header {
  color: #e2e3ea;
  background: #353a52;
}

.root_84b0db .ant-layout-content {
  justify-content: center;
  display: flex;
  overflow-y: auto;
}

.root_84b0db .ant-tabs {
  width: 100%;
  max-width: 150em;
  height: max-content;
  padding: 5em 3em;
  overflow: visible;
}

.root_84b0db .tabs_84b0db .ant-tabs-tab {
  font-size: 1.15em;
}

.root_84b0db .tabs_84b0db .ant-tabs-tab > * {
  text-shadow: none;
}

.userFirstLetterWrapper_84b0db {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.userFirstLetter_84b0db {
  background: #9093a5;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

.title_84b0db {
  color: #fff;
}

.menu_84b0db {
  color: #e2e3ea;
  font-size: 1.16em;
}

.tabs_84b0db {
  padding: 5em 0;
}

.tabs_84b0db .ant-tabs-tab {
  font-size: 1.16em;
}

.content_84b0db {
  overflow-y: auto;
}

.libraryTitle_84b0db {
  letter-spacing: 0;
  padding-right: 5em;
  font-family: Roboto Slab;
  font-size: 2.15em;
  font-weight: 400;
}

.ant-tabs-nav {
  border-bottom: 1px solid #e2e3ea;
}

.projects_84b0db {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: calc(12em + 3vw);
  gap: 1.41em;
  padding-top: 1.2em;
  display: grid;
}

@media (width <= 1400px) {
  .projects_84b0db {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: calc(2.9em + 20vw);
  }
}

@media (width <= 900px) {
  .projects_84b0db {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: calc(2.9em + 30vw);
  }
}

.h1_19b898 {
  letter-spacing: 0;
  font-family: Roboto Slab;
  font-weight: 400;
}

.h2_19b898 {
  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 500;
}

.project_19b898 {
  color: #fff;
  text-align: center;
  overflow-wrap: anywhere;
  background: #9093a5;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  place-self: center;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  display: flex;
  position: relative;
}

.project_19b898.isInReSync_19b898 {
  cursor: unset;
}

.badge_19b898 {
  position: absolute;
  top: 0;
  right: 0;
}

.homeContainer_e8e069 {
  flex: 1;
  display: flex;
}

.narrationAppContainer_e8e069 {
  flex: 1;
  max-height: 100%;
  display: flex;
  position: relative;
}

.bookPanelHeader_544cf9 {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6em;
  padding: 0 1.5em;
  line-height: 0%;
  display: flex;
  box-shadow: inset 0 -1px #5b60791f;
}

.bookPanelHeaderTitle_544cf9 {
  color: #353a52;
  font-family: Roboto Slab;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
}

.filters_544cf9 {
  margin: .4em 0;
}

.filters_544cf9 .filterCheckboxContainer_544cf9 {
  justify-content: space-between;
  align-items: center;
  margin-top: .2em;
  padding: .6em 1.5em;
  display: flex;
}

.filters_544cf9 .filterCheckboxContainer_544cf9 label {
  opacity: .8;
}

.filters_544cf9 .filterCheckboxContainer_544cf9 > p {
  opacity: .95;
  margin-bottom: 3px;
  font-size: .82em;
}

.pageTabContainer_544cf9, .definitions_544cf9 {
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 1.5em;
  display: flex;
  box-shadow: inset 0 -1px #30323a1f;
}

.pageTabContainer_544cf9 .rightIcon_544cf9, .definitions_544cf9 .rightIcon_544cf9 {
  font-size: 18px;
}

.pageTabContainer_544cf9[data-disabled="true"], .definitions_544cf9[data-disabled="true"] {
  opacity: .5;
  cursor: not-allowed;
}

.pageTabContainer_544cf9[data-disabled="true"] .rightIcon_544cf9, .definitions_544cf9[data-disabled="true"] .rightIcon_544cf9 {
  opacity: .35;
}

.pageTabContainer_544cf9.pageTabContainer_544cf9 .rightIcon_544cf9, .definitions_544cf9.pageTabContainer_544cf9 .rightIcon_544cf9 {
  margin-left: .7em;
}

.pageTabContainer_544cf9.pageTabContainer_544cf9 .rightIcon_544cf9.hidden_544cf9, .definitions_544cf9.pageTabContainer_544cf9 .rightIcon_544cf9.hidden_544cf9 {
  opacity: 0;
}

.pageTabContainer_544cf9:hover, .definitions_544cf9:hover {
  background-color: #fafafa;
}

.researchInputContainer_544cf9 {
  border-bottom: 1px solid #e2e3ea7a;
  width: 100%;
  height: 2em;
}

.searchInput_544cf9 {
  color: #5b6079;
  background-color: #e2e3ea7a;
  border: none;
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 1em;
  font-family: Roboto;
  font-style: normal;
}

.bookPanelPageContainer_544cf9 {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.drawer_544cf9 .ant-drawer-body {
  flex-direction: column;
  height: 100%;
  padding: 0;
  font-size: 1em;
  display: flex;
  overflow: hidden;
}

.searchIcon_544cf9 {
  position: absolute;
  right: 2em;
}

.spinner_544cf9 {
  margin-top: 3em;
}

.headerHomeContainer_47b3c7 {
  color: #fff;
  background-color: #353a52;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  display: flex;
}

.headerBookContainer_47b3c7 {
  color: #fff;
  background-color: #353a52;
  border: 1px solid #5b607913;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
  box-shadow: 0 5px 15px #00000040;
}

.headerTitleContainer_47b3c7 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 24px 16px;
  display: flex;
}

.userMenu_47b3c7 {
  background: #9093a5;
  border-radius: 250px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.userFirstLetter_47b3c7 {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.emailInHeader_47b3c7 {
  letter-spacing: 0;
  opacity: 1;
  margin: 10px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 800;
  display: inline;
}

.logoutButton_47b3c7 {
  cursor: pointer;
  text-align: center;
  letter-spacing: 0;
  opacity: 1;
  font: normal normal medium 14px / 16px Roboto;
  color: #fff;
  background: #e66525 no-repeat padding-box padding-box;
  border: 1px solid #e66525;
  border-radius: 80px;
  margin: 20px;
  padding: 10px;
}

.bookInfo_47b3c7 {
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0;
  display: flex;
}

.headerTitle_47b3c7 {
  color: #fff;
  margin: 0;
  font-family: Roboto Slab;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.headerAuthor_47b3c7 {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.paginationBar_47b3c7 {
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  display: flex;
  box-shadow: inset 0 1px #ffffff3d;
}

.pagination_47b3c7 {
  display: flex;
}

.hidden_47b3c7 {
  opacity: 0;
  pointer-events: none;
}

.halfHidden_47b3c7 {
  opacity: .3;
  pointer-events: none;
}

.headerSpinner_47b3c7 {
  margin: auto;
  position: absolute;
  inset: 5px 0 0;
}

.pageContent_cc117c {
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  padding: 0 2em;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.pageContent_cc117c > div {
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 70em;
  padding: 2em 0;
  display: flex;
}

.resyncOverlay_1ea007 {
  z-index: 99999;
  background-color: #ffffffb3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.resyncOverlay_1ea007 .anticon {
  margin-top: .3em;
  font-size: 1.8em;
}

.resyncOverlay_1ea007 h2 {
  font-size: 1.5em;
}

.root_fcd1b4 {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.noResult_fcd1b4 {
  margin: auto;
}

.noResult_fcd1b4 span[role="img"] {
  color: #9093a5 !important;
}

.noResult_fcd1b4 .noResultTitle_fcd1b4 {
  color: #9093a5;
  font-size: .75em;
}

.setStatusBar_fcd1b4 {
  z-index: 999;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2em;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 5px 15px #00000040;
}

.setStatusBar_fcd1b4 .actions_fcd1b4 {
  align-items: center;
  column-gap: 1em;
  display: flex;
}

.paragraphs_fcd1b4 {
  flex-direction: column;
  flex: 1;
  row-gap: 2em;
  padding-bottom: 8em;
  display: flex;
  overflow-y: auto;
}

.audioPlayer_fcd1b4 {
  pointer-events: none;
  justify-content: center;
  column-gap: 1em;
  width: 100%;
  padding: 2em;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.speedControlsContainer_fcd1b4 {
  display: flex;
  position: relative;
}

.speedControlsContainer_fcd1b4 .speedControls_fcd1b4 {
  position: absolute;
  right: 0;
}

.buttonsRow_fcd1b4 {
  pointer-events: all;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  padding: 2em;
  display: flex;
}

.buttonsRow_fcd1b4 > .playPause_fcd1b4 {
  pointer-events: initial;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  padding: 0;
  font-size: 2em;
  display: grid;
  width: 2.5em !important;
}

.buttonsRow_fcd1b4 button {
  min-height: unset;
  border-radius: 100% !important;
}

.buttonsRow_fcd1b4 .ant-progress .ant-progress-circle {
  background-color: #fff;
}

.rhese_fcd1b4 {
  background: #fff;
  border-left: .15em solid #0000;
  flex: 1;
  place-content: center space-between;
  padding: .5em;
  display: flex;
  position: relative;
}

.rhese_fcd1b4 .anchor_fcd1b4 {
  background-color: #e66525;
  border-radius: 50%;
  width: .75em;
  height: .75em;
  position: absolute;
  top: 50%;
  left: -2em;
  transform: translateY(-50%);
}

.rhese_fcd1b4 .content_fcd1b4 {
  align-items: center;
  display: flex;
}

.rhese_fcd1b4[data-selected="true"] {
  border-left: .15em solid #3e87dd;
}

.rhese_fcd1b4[data-rhese-to-play="false"]:not([data-selected="true"], :hover) {
  opacity: .5;
}

.rhese_fcd1b4:hover {
  cursor: pointer;
  border-left: .15em solid #3e87dd;
}

.rheseContainer_fcd1b4 {
  flex-direction: row;
  column-gap: 1em;
  width: 100%;
  display: flex;
}

.rheseContainer_fcd1b4 .rheseAudioDuration_fcd1b4 {
  align-self: center;
  margin-bottom: 0;
  font-size: .8em;
}

.playRhese_fcd1b4, .silentIndicator_fcd1b4 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.playRhese_fcd1b4 button, .silentIndicator_fcd1b4 button, .playRhese_fcd1b4 .fakeBtn_fcd1b4, .silentIndicator_fcd1b4 .fakeBtn_fcd1b4 {
  justify-content: center;
  align-items: center;
  height: 2.5em;
  padding: 0;
  display: grid;
  width: 2.5em !important;
}

.playRhese_fcd1b4 .stopIcon_fcd1b4, .silentIndicator_fcd1b4 .stopIcon_fcd1b4 {
  width: 13px;
}

.playRhese_fcd1b4 .stopIcon_fcd1b4 *, .silentIndicator_fcd1b4 .stopIcon_fcd1b4 * {
  fill: #e66525;
}

.playRhese_fcd1b4.silentIndicator_fcd1b4 svg, .silentIndicator_fcd1b4.silentIndicator_fcd1b4 svg {
  fill: #9093a5;
}

.comment_fcd1b4 {
  background: #fff;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.comment_fcd1b4 > div:not(:last-child) {
  box-shadow: inset 0 -1px #5b60791f;
}

.comment_fcd1b4 .title_fcd1b4 {
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  display: flex;
}

.comment_fcd1b4 .inputs_fcd1b4 {
  flex-direction: column;
  flex: 1;
  row-gap: 1em;
  padding: 2em 4em;
  display: flex;
}

.comment_fcd1b4 .controls_fcd1b4 {
  background: #fafafa;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0;
  display: flex;
}

.comment_fcd1b4 .controls_fcd1b4 .playButton_fcd1b4 {
  justify-content: center;
  align-items: center;
  height: 2.5em;
  padding: 0;
  font-size: 1.5em;
  display: grid;
  width: 2.5em !important;
}

.audioReview_fcd1b4 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  display: flex;
}

.comment_fcd1b4 {
  flex-direction: column;
  row-gap: 1em;
  display: flex;
}

.pageNav_fcd1b4 {
  pointer-events: initial;
  height: 3.5em;
  width: 3.5em !important;
}

.statusSelector_fcd1b4 {
  min-width: 17em;
}

.iconIgnoredFromSynchro_fcd1b4 {
  padding-left: .3em;
  padding-right: .5em;
  font-size: 16px;
}

.root_465f71.readOnly_465f71 .ant-modal-body textarea {
  border: none;
}

.root_465f71:not(.readOnly_465f71) .ant-modal-body textarea {
  min-height: 20em;
}

.definitions_e6ba48 {
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  padding: 2em 0;
  display: flex;
  position: relative;
}

.definitions_e6ba48.definitionsProofListener_e6ba48 {
  margin-bottom: 2em;
  padding-top: .2em;
}

.definitionWrapper_e6ba48 {
  align-items: center;
  width: 100%;
  display: flex;
}

.definition_e6ba48 {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 1em 2em;
}

.definitionLabel_e6ba48 {
  flex-grow: 1;
}

.recorder_e6ba48 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.recorder_e6ba48:hover {
  cursor: pointer;
}

.recordButton_e6ba48, .playButton_e6ba48 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.stopIcon_e6ba48 {
  width: 13px;
}

.stopIcon_e6ba48 * {
  fill: #e66525;
}

.recordSpinner_e6ba48 {
  margin-top: 6px;
}

.definitionActions_e6ba48 {
  grid-template-columns: 3em 3em;
  grid-auto-flow: column;
  display: grid;
}

.definitionActions_e6ba48 > * {
  width: min-content;
}

.audioDropInput_e6ba48 {
  margin-left: 8px;
}

.audioDropInput_e6ba48 .ant-upload-drag {
  border-radius: 7px;
  width: 55px;
  height: 64px;
}

.audioDropInput_e6ba48 .ant-upload-drag .ant-upload-btn {
  padding: 0;
}

.audioDropInput_e6ba48 .ant-upload-drag-hover {
  background-color: #e2e3ea;
}

.audioDropInput_e6ba48 .uploadIcon_e6ba48 {
  font-size: 16px;
}

.audioReview_fa677f {
  padding: 0 1em;
}

.buttonContainer_ebbefc {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.recorderContainer_ebbefc {
  z-index: 2;
  background-color: #fff;
  border: 1px solid #5b607913;
  border-radius: 16px;
  flex-direction: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto 1fr;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 1em;
  width: 100%;
  max-width: 40em;
  height: max-content;
  min-height: 6em;
  margin-bottom: .5em;
  padding: 1em 4em;
  display: grid;
  box-shadow: 0 0 15px #00000014;
}

.rowOne_ebbefc {
  grid-row: 1;
  justify-self: center;
}

.recorderSwitchContainer_ebbefc {
  flex-direction: row;
  grid-area: 2 / 1 / auto / 4;
  place-self: end center;
  align-items: center;
  margin-top: 5px;
  display: flex;
}

.recorderSwitchContainerIcon_ebbefc {
  color: gray;
  margin-bottom: .2em;
  margin-left: .2em;
  margin-right: .2em;
}

.recordingArea_ebbefc {
  flex-direction: column;
  grid-column: 2 / 3;
  justify-content: center;
  align-items: center;
  height: max-content;
  display: flex;
}

.wrapper_ebbefc {
  z-index: 2;
  justify-content: center;
  width: 100%;
  display: flex;
  position: sticky;
  bottom: 0;
}

.recorderInstructionContainer_ebbefc {
  background-color: #f8eaea;
  margin-bottom: 8px;
  padding: 8px 16px 16px;
}

.recorderInstruction_ebbefc {
  color: #e33636;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.validationContainer_ebbefc {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  display: flex;
}

.validationContainer_ebbefc .buttons_ebbefc {
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1em;
  display: flex;
}

.button_ebbefc {
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.cancelButton_ebbefc {
  background-color: #e2e3ea7a;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.settingsButton_ebbefc {
  border: none;
  width: 26px;
  height: 26px;
  margin-left: 1.5rem;
}

.validationButtonText_c32987 {
  color: #61c374;
  padding: 12px 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.validationButtonContainer_c32987 {
  background-color: #61c3741f;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  display: flex;
}

.rheseRoot_9e3ba8 {
  position: relative;
}

.rheseRoot_9e3ba8:hover {
  cursor: pointer;
}

.rheseRoot_9e3ba8:hover .rheseContainer_9e3ba8 {
  border-left: 3px solid #6aa5eb;
  border-right: 3px solid #6aa5eb;
}

.rheseRoot_9e3ba8:first-child .rheseContainer_9e3ba8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rheseRoot_9e3ba8:last-child .rheseContainer_9e3ba8 {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rheseContainer_9e3ba8 {
  border-left: 3px solid #0000;
  border-right: 3px solid #0000;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 1em;
  display: flex;
  overflow: hidden;
}

.rheseWhite_9e3ba8 {
  background-color: #fff;
}

.rheseGrey_9e3ba8 {
  background-color: #fafafa;
}

.checkboxContainer_9e3ba8 {
  padding: 16px;
}

.rhese_9e3ba8 {
  flex: 1;
  margin: 0;
  padding: 16px 0;
  font-family: Roboto;
  transition: all .2s cubic-bezier(.445, .05, .55, .95);
}

.selectedIcon_9e3ba8, .selectionStart_9e3ba8 {
  position: absolute;
  top: 50%;
  left: -1em;
  transform: translateX(-50%)translateY(-50%);
}

.selectionStart_9e3ba8 {
  background-color: #e66525;
  border-radius: 50%;
  width: .5em;
  height: .5em;
}

.iconIgnoredFromSynchro_9e3ba8 {
  margin-left: -.3em;
  padding-left: .3em;
  padding-right: .9em;
  font-size: 16px;
}

.iconIgnoredFromSynchro_9e3ba8:hover * {
  fill: #e66525;
}

.textContainer_960840 {
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  height: fit-content;
  margin-top: 2em;
  margin-bottom: 4em;
  display: flex;
}

.textToRead_960840 {
  flex: 1;
  justify-content: center;
  display: flex;
  position: relative;
}

.paragraph_960840 {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
}

.unselect_960840 {
  font-size: 1em;
  position: sticky;
  top: 2em;
  transform: translateX(-100%);
}

.toggleRheseSilence_960840 {
  height: 4.6em;
  position: absolute;
  left: 0;
  transform: translateX(-110%);
}

.root_8b6166 .subContainer_8b6166:first-of-type {
  transform: translateX(-30%);
}

.root_8b6166 .subContainer_8b6166:last-of-type {
  transform: translateX(30%);
}

.root_8b6166 .subContainer_8b6166 .button_8b6166 {
  min-width: 2.9em;
  margin-left: .5em;
  padding: 0;
}

.root_8b6166 .subContainer_8b6166 .icon_8b6166 {
  font-size: .7em;
  transform: translateY(0);
}

.content_1688eb {
  flex-direction: column;
  row-gap: 1em;
  display: flex;
}

.content_1688eb .sliderContainer_1688eb {
  padding: 2em 0;
}

.content_1688eb .inputRow_1688eb {
  margin-bottom: 1em;
}

.content_1688eb .inputRow_1688eb .input_1688eb {
  flex: 1;
  width: auto;
}

.content_1688eb .inputRow_1688eb .input_1688eb:first-of-type {
  border-radius: 8px 0 0 8px;
}

.content_1688eb .inputRow_1688eb .input_1688eb:last-of-type {
  border-radius: 0 8px 8px 0;
}

.content_1688eb .inputRow_1688eb .durationAndPlayBtn_1688eb {
  text-align: center;
}

.content_1688eb .inputRow_1688eb .durationAndPlayBtn_1688eb .duration_1688eb {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 5em;
  margin: .3em 1em 0;
  display: flex;
}

.content_1688eb .inputRow_1688eb .durationAndPlayBtn_1688eb .listenButton_1688eb {
  border: none;
  border-radius: 100px;
  width: 1.4em;
  min-width: auto;
  height: 1.4em;
  margin-top: 2px;
  padding: 0;
  font-size: 1.8em;
}

.content_1688eb .inputRow_1688eb .durationAndPlayBtn_1688eb .listenButton_1688eb svg {
  margin-top: 4px;
}

.content_1688eb .inputRow_1688eb .durationAndPlayBtn_1688eb .listenButton_1688eb svg path {
  fill: #5b6079;
}

.content_1688eb .actionBar_1688eb {
  justify-content: space-between;
  margin-top: 3em;
  display: flex;
}

.content_1688eb h3 {
  text-align: center;
}

.content_1688eb .buttonReset_1688eb {
  color: #e66525;
  border-color: #e66525;
  margin-left: .4em;
  margin-right: .4em;
}

.content_1688eb .buttonReset_1688eb:hover {
  opacity: .8;
  color: #fff;
  background-color: #e66525;
}

.extender_1688eb {
  flex-grow: 1;
}

.popover_0bdf41 {
  pointer-events: initial;
  border-radius: 120px;
  align-self: center;
}

.content_0bdf41 {
  background-color: #fff;
  border-radius: 20px;
  width: 20em;
  padding: .6em;
}

.popoverButton_0bdf41 {
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  min-width: 6.6em;
  height: 3.3em;
  padding: 0 1em;
  display: flex;
}

.popoverButton_0bdf41:focus {
  color: #353a52;
  border-color: #353a52;
}

.speedIcon_0bdf41 {
  font-size: 20px;
}

.ant-popover-inner {
  border-radius: 14px;
}

.ant-slider-dot-active {
  background-color: #fff;
  border: 2px solid #f0f0f0;
}

#app {
  display: flex;
}

.container_763b4f {
  text-orientation: upright;
  writing-mode: vertical-rl;
  letter-spacing: .1em;
  text-align: right;
  padding: .25em;
}

.container_763b4f.development_763b4f {
  background: #ffee91;
}

.container_763b4f.test_763b4f {
  background: #ff925b;
}
/*# sourceMappingURL=index.30b27b6c.css.map */
