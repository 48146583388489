@import "./colors.less";
@import "./fonts.less";

/*
  Ant override since v4
*/

.ant-btn.ant-btn-ghost.ant-btn-round {
  padding: 0.65em 2.5em;
  font-size: 1.16em;
  color: @primary-color;
  border-color: @primary-color;
  height: auto;
}

.ant-btn.ant-btn-ghost.ant-btn-round:disabled {
  color: fade(@primary-color, 30%);
  border-color: fade(@primary-color, 30%);
  background: transparent;
}

.ant-btn.ant-btn-ghost.ant-btn-round:hover {
  background-color: @primary-color;
  color: @white;

  &:disabled {
    background: transparent;
    color: fade(@primary-color, 30%);
    border-color: fade(@primary-color, 30%);
  }
}

.ant-input {
  font-size: 1.16em;
  color: @darkest-grey;
  box-shadow: none;

  &:hover {
    border-color: fade(@dark-grey, 25%);
    background-color: fade(@light-grey, 10%);
  }

  &:focus {
    border-color: @primary-color;
    background-color: fade(@light-grey, 10%);
    box-shadow: none;
  }
}

.ant-input::placeholder {
  font-style: italic;
  color: @grey;
}

.ant-modal-header {
  padding: 0;

  .ant-modal-title {
    .h1();
    text-align: center;
    font-size: 2em;
    line-height: 3.33em;
    padding: 0;
  }
}

.ant-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ant-radio-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
  Ant override since v5
*/

/* Link */

a {
  color: @primary-color;
  &:hover {
    color: lighten(@primary-color, 15%);
  }
}

/* Body */
body {
  line-height: 1.5715;
}

/* Pagination */
.ant-pagination {
  font-weight: 400;
}

/* Form item */
.ant-form-item {
  margin-bottom: 2.5em;
}
.ant-form-item-label label {
  font-size: 1.16em !important;
}

/* Switch */
.ant-switch .ant-switch-handle::before {
  border-radius: 100%; // FIX
}

/* Checkbox */
tbody {
  border-spacing: initial; // FIX checkbox mis-alignement within a table
}

/* Input  */
.ant-input {
  padding: 1em;
}

/* Input Textarea */
textarea.ant-input {
  padding: 16px;
}

/* Input Drag&drop */
.ant-upload.ant-upload-drag {
  background-color: white;
}

/* List */
.ant-list .ant-list-item {
  display: flex; // overrides ".ant-list .ant-list-item-no-flex" selector with block display
}

/* Menu */
.ant-dropdown-trigger {
  font-weight: 500 !important;
}
.ant-dropdown-menu {
  border-radius: 2px !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
  padding: 8px 6px;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu-title-content {
  font-weight: 500 !important;
}
.ant-select-dropdown .ant-select-item {
  align-items: center;
  padding: 10px 12px;
}

/* Select */
.ant-select-selector {
  height: 60px;
  align-items: center;
}
.ant-select-selector .ant-select-selection-search {
  display: flex;
  align-items: center;
}
.ant-select .ant-select-arrow {
  top: 0;
  bottom: 0;
  margin: auto;
  margin-top: 2px;
}
.ant-select-selection-item {
  text-align: left;
  font-size: 0.9em;
}
.ant-select-item-option-content {
  font-size: 0.9em;
}

/* Dropdown & Select down-arrow */
.ant-dropdown-trigger .anticon-down {
  transform: scale(0.8);
  * {
    fill: rgba(0, 0, 0, 0.25);
  }
}
.ant-select-arrow {
  transform: scale(0.8);
}

/* Button */
.ant-btn {
  font-weight: 500;
  min-height: 2.857em;
}

/* Radio Button */
.ant-radio-button-wrapper span {
  white-space: nowrap;
}

/* Breadcrumb */
a.ant-breadcrumb-link {
  color: @darkest-grey !important;
  &:hover {
    background-color: transparent !important;
  }
}

/* Active, Hover, focus-visible */
.ant-btn-default:not(:disabled):hover {
  color: @primary-color;
  border-color: @primary-color;
}

*:focus-visible {
  outline: none !important;
  outline-offset: none !important;
}

/* Modal */
.ant-modal-confirm-content {
  white-space: pre-line; // allow \n from json to take effect
}
