@import "./antd.override.less";

@import (css)
  url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;0,700;1,200;1,300;1,400;1,500;1,700&display=swap");
@import (css)
  url("https://fonts.googleapis.com/css2?family=Roboto+Slab:ital,wght@0,200;0,300;0,400;0,500;0,700;1,200;1,300;1,400;1,500;1,700&display=swap");
@import (css)
  url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,200;0,300;0,400;0,500;0,700;1,200;1,300;1,400;1,500;1,700&display=swap");
@import "./colors.less";

html * {
  box-sizing: border-box;
}

html body {
  font-family: "Roboto";
  font-weight: 500;
  overflow: hidden;
  font-size: 1rem;
  color: @darkest-grey;
  padding: 0;
  margin: 0;
}

html {
  font-size: min(calc(11px + 0.3vw), 16px);
}

div:focus {
  outline: none;
}

::selection {
  background: @orange;
  color: white;
}
