@import "../../../styles/colors.less";

:global {
  .paragraph .space {
    display: inline-block;
  }

  .rheseWrapper {
    display: inline-block;
    white-space: nowrap; // fixes issue when adding/removing class's tooltip
  }

  .insecable {
    background-color: @grey;
  }

  .isSearchResult {
    background-color: @blue;
  }

  .rhese .rheseNumber {
    display: none;
    user-select: none;
    &::selection {
      background-color: transparent;
      color: inherit;
    }
  }
  .paragraph.TITRE_1 {
    font-size: 1.15em;
    letter-spacing: -0.32px;
    text-align: center;
    font-weight: bold;
  }

  .paragraph.TITRE_2 {
    text-transform: uppercase;
    letter-spacing: -0.28px;
    font-weight: bold;
  }

  .paragraph.TITRE_3 {
    letter-spacing: -0.28px;
    font-weight: 500;
    text-decoration: underline;
    text-underline-position: under;
  }

  .paragraph.TITRE_4::before {
    content: "→ ";
  }

  .paragraph.TITRE_4 {
    letter-spacing: -0.28px;
    font-weight: 500;
    color: fade(@black, 60%);
  }

  .paragraph.INTRO {
    letter-spacing: -0.28px;
    font-weight: 500;
    color: fade(@black, 70%);
  }

  .paragraph.RESUME {
    font-weight: 300;
    color: fade(@black, 80%);
  }

  .paragraph.DIALOGUE::before {
  }

  .paragraph.DIALOGUE {
    line-height: 1.42;
  }

  .highlightedSpace {
    background-color: @blue;
  }
  .borderGreen {
    border-right: 4px solid @green;
  }
  .circleContainerTooltipClasses {
    position: relative;
  }
  .circleTooltipClasses {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -10px;
    max-width: 3.5px;
    max-height: 3.5px;
    min-width: 3.5px;
    min-height: 3.5px;
    background-color: @orange;
    border-radius: 100%;
  }
}
