@classic: "Roboto";
@mono: "Roboto Mono", monospace;
@slab: "Roboto Slab";

.h1 {
  font-family: @slab;
  font-weight: 400;
  letter-spacing: 0;
}

.h2 {
  font-family: @classic;
  font-weight: 500;
  letter-spacing: 0;
}
